import React from 'react';
const Footer = () => (
  <div>
    <footer className="text-center bg-dark text-light">
      <p>
        <small>
          <small>
            made with ❤ by
            <a href="https://mesinkasironline.web.app">
              mesinkasironline.web.app
            </a>
          </small>
        </small>
      </p>
    </footer>
  </div>
);

export default Footer;
